<template>
    <div
        class="row align-items-center border-bottom py-3"
        v-bind:class="{ kiemelt: productData.order !== 999 }"
    >
        <div class="col-auto text-center mb-4 mb-md-0" v-if="!noCompare">
            <input
                type="checkbox"
                v-model="isCompare"
                :value="productData.id"
            />
        </div>
        <div class="col-auto mb-4 mb-md-0">
            <router-link
                :to="{
                    name: 'Product',
                    params: {
                        slug: productData.kod,
                        emarka: productData.emarka,
                        width: productData.width,
                        profile: productData.profile,
                        coll: productData.coll
                    }
                }"
                ><img
                    v-lazy="
                        'http://images.akh.hu/mintazatok/' + productData.kepnev
                    "
                    class="tyre-img"
                />
            </router-link>
        </div>
        <div class="col mb-4 mb-md-0">
            <img
                :src="
                    appUrl +
                        '/storage/images/brands/' +
                        productData.emarka +
                        '.jpg'
                "
                class="w-50"
            />
            <div>
                <router-link
                    :to="{
                        name: 'Product',
                        params: {
                            slug: productData.kod,
                            emarka: productData.emarka,
                            width: productData.width,
                            profile: productData.profile,
                            coll: productData.coll
                        }
                    }"
                    class="text-black"
                    ><strong
                        >{{ productData.cicsopnev }} <br />
                        {{ productData.cikknev }} <br />
                        {{ m1NevSwitcher(productData.m1nev) }}</strong
                    >
                </router-link>
            </div>
        </div>
        <div class="col-6 col-md-auto list-small-text mb-4 mb-md-0">
            <div class="row justify-content-center">
                <div class="col-2 col-md text-center p-0">
                    <img src="/img/gas.svg" class="small-icon" />
                    <br />{{ productData.fogyasztas }}
                </div>
                <div class="col-2 col-md text-center p-0">
                    <img src="/img/break.svg" class="small-icon" />
                    <br />{{ productData.fekezes }}
                </div>
                <div class="col-2 col-md text-center p-0">
                    <img src="/img/sound.svg" class="small-icon" />
                    <br />{{ productData.zaj }}
                    <div class="col-4"></div>
                </div>
            </div>
        </div>
        <div
            class="col-6 col-md-1 text-center list-small-text mb-4 mb-md-0 d-none d-md-block"
        >
            <div>
                <img
                    v-if="
                        productData.m1nev === 'Nyári abroncs' ||
                            productData.m1nev === 'Négyévszakos abroncs'
                    "
                    src="/img/summer.svg"
                    class="small-icon"
                />
                <img
                    v-if="
                        productData.m1nev === 'Téli abroncs' ||
                            productData.m1nev === 'Négyévszakos abroncs'
                    "
                    src="/img/winter.svg"
                    class="small-icon"
                />
            </div>
            {{ m1NevSwitcher(productData.m1nev) }}
        </div>
        <div class="col-6 col-md-2 text-center list-small-text mb-4 mb-md-0">
            <div>
                <img
                    src="/img/tyre_in.svg"
                    class="small-icon"
                    v-if="productData.tat >= 1"
                />
                <img
                    src="/img/tyre_in.svg"
                    class="small-icon"
                    v-if="productData.tat >= 2"
                />
                <img
                    src="/img/tyre_in.svg"
                    class="small-icon"
                    v-if="productData.tat >= 3"
                />
                <img
                    src="/img/tyre_in.svg"
                    class="small-icon"
                    v-if="productData.tat >= 4"
                />
                <img
                    src="/img/plus.svg"
                    class="small-icon"
                    v-if="productData.tat > 4"
                />
            </div>
            Raktáron
            <template v-if="productData.tat < 5">{{ productData.tat }}</template
            ><template v-else>4+</template> darab
        </div>
        <div class="col-auto col-md">
            <div class="price-box text-end">
                <!--<div
                    class="price-original"
                    v-if="productData.special_price !== 0"
                >
                    Eredeti ár: {{ productData.calculated_netto }} Ft
                </div>-->
                <div
                    class="price-actual"
                    v-if="productData.special_price !== 0"
                >
                    {{ productData.calculated_special_price }} Ft
                </div>
                <div class="price-actual" v-else>
                    {{ productData.calculated_netto }} Ft
                </div>
                <!--<div
                    class="price-discount"
                    v-if="productData.special_price !== 0"
                >
                    -{{ productData.calculated_percent }}% <br />kedvezmény
                </div>-->
            </div>
        </div>
        <div class="col col-md">
            <div class="d-flex align-items-center">
                <input
                    type="number"
                    min="1"
                    :max="productData.tat"
                    name="quantity"
                    class="form-control list-input-padding"
                    v-model="quantity"
                    oninput="this.value = Math.abs(this.value)"
                />
                <div class="px-3">db</div>
                <button
                    class="btn btn-primary list-input-padding"
                    v-on:click="addCart"
                >
                    <img src="/img/cart.svg" class="list-carticon" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductRow",
    props: ["productData", "noCompare"],
    data() {
        return {
            isCompare: false,
            quantity: null
        };
    },
    watch: {
        isCompare: function(val) {
            this.$emit("setCompareId", {
                checked: val,
                id: this.productData.id
            });
        },
        $route: {
            handler: function() {
                this.setDefaultQuantity();
            },
            deep: true
        },
        productData: {
            handler: function(val) {
                if (val.tat > 4) {
                    this.quantity = 4;
                } else {
                    this.quantity = val.tat;
                }
            },
            deep: true
        }
    },
    methods: {
        addCart() {
            this.addToCart(this.productData.id, this.quantity);
        },
        setDefaultQuantity() {
            if (this.productData.tat > 4) {
                this.quantity = 4;
            } else {
                this.quantity = this.productData.tat;
            }
        }
    },
    mounted() {
        this.setDefaultQuantity();
    },
    computed: {
        appUrl: function() {
            return process.env.VUE_APP_APP_URL;
        },
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    }
};
</script>

<style scoped></style>
