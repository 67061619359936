<template>
    <div class="content">
        <section class="my-5">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-8 offset-md-2 text-center">
                        <template
                            v-if="
                                this.$route.params.slug.startsWith(
                                    'nyari-gumi'
                                ) ||
                                    this.$route.params.slug.startsWith(
                                        'teli-gumi'
                                    ) ||
                                    this.$route.params.slug.startsWith(
                                        'negegyvszakos-gumi'
                                    )
                            "
                        >
                            <h1 class="title mb-4">
                                <template
                                    v-if="
                                        params[2] &&
                                            params[3] &&
                                            params[4] &&
                                            params[2] !== 'null'
                                    "
                                >
                                    {{ params[2] }} {{ params[3] }} R{{
                                        params[4]
                                    }}
                                </template>
                                <template v-if="params[0] === 'nyari'"
                                    >Nyári
                                </template>
                                <template v-if="params[0] === 'teli'"
                                    >Téli
                                </template>
                                <template v-if="params[0] === 'negyevszakos'"
                                    >Négyévszakos
                                </template>
                                gumi akciós árak
                            </h1>
                            <p>
                                Akár
                                <template v-if="model && model.lowest_price">{{
                                    model.lowest_price
                                }}</template>
                                Ft-tól
                                <template
                                    v-if="
                                        params[2] &&
                                            params[3] &&
                                            params[4] &&
                                            params[2] !== 'null'
                                    "
                                >
                                    {{ params[2] }} {{ params[3] }} R{{
                                        params[4]
                                    }}
                                </template>
                                <template v-if="params[0] === 'nyari'"
                                    >nyári
                                </template>
                                <template v-if="params[0] === 'teli'"
                                    >téli
                                </template>
                                <template v-if="params[0] === 'negyevszakos'"
                                    >négyévszakos
                                </template>
                                gumi másnapi kiszállítással, raktárról!
                                <br />Rendeljen most, mert nálunk bankkártyával
                                és utánvéttel is fizethet!
                            </p>
                        </template>
                        <template
                            v-if="
                                params[1] === 'nyari' ||
                                    params[1] === 'teli' ||
                                    params[1] === 'negyevszakos'
                            "
                        >
                            <h1 class="title mb-4">
                                {{ params[0] }}
                                <template
                                    v-if="
                                        params[3] &&
                                            params[4] &&
                                            params[5] &&
                                            params[3] !== 'null'
                                    "
                                >
                                    {{ params[3] }} {{ params[4] }} R{{
                                        params[5]
                                    }}
                                </template>
                                <template v-if="params[1] === 'nyari'"
                                    >Nyári
                                </template>
                                <template v-if="params[1] === 'teli'"
                                    >Téli
                                </template>
                                <template v-if="params[1] === 'negyevszakos'"
                                    >Négyévszakos
                                </template>
                                gumi akciós árak
                            </h1>
                            <p>
                                Akár
                                <template v-if="model && model.lowest_price">{{
                                    model.lowest_price
                                }}</template>
                                Ft-tól
                                {{ capitalizeFirstLetter(params[0]) }}
                                <template
                                    v-if="
                                        params[3] &&
                                            params[4] &&
                                            params[5] &&
                                            params[3] !== 'null'
                                    "
                                >
                                    {{ params[3] }} {{ params[4] }} R{{
                                        params[5]
                                    }}
                                </template>
                                <template v-if="params[1] === 'nyari'"
                                    >nyári
                                </template>
                                <template v-if="params[1] === 'teli'"
                                    >téli
                                </template>
                                <template v-if="params[1] === 'negyevszakos'"
                                    >négyévszakos
                                </template>
                                gumi másnapi kiszállítással, raktárról!
                                <br />Rendeljen most, mert nálunk bankkártyával
                                és utánvéttel is fizethet!
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <ul class="breadcrumbs">
                        <li>
                            <router-link to="/">Autógumi kereső</router-link>
                        </li>
                        <li
                            v-if="
                                params[0] === 'teli' ||
                                    params[0] === 'nyari' ||
                                    params[0] === 'negyevszakos'
                            "
                        >
                            <router-link :to="'/' + params[0] + '-' + params[1]"
                                ><template v-if="params[0] === 'nyari'"
                                    >Nyári
                                </template>
                                <template v-if="params[0] === 'teli'"
                                    >Téli
                                </template>
                                <template v-if="params[0] === 'negyevszakos'"
                                    >Négyévszakos
                                </template>
                                gumi</router-link
                            >
                        </li>
                        <li
                            v-if="
                                params[1] === 'teli' ||
                                    params[1] === 'nyari' ||
                                    params[1] === 'negyevszakos'
                            "
                        >
                            <router-link :to="'/' + params[1] + '-' + params[2]"
                                ><template v-if="params[1] === 'nyari'"
                                    >Nyári
                                </template>
                                <template v-if="params[1] === 'teli'"
                                    >Téli
                                </template>
                                <template v-if="params[1] === 'negyevszakos'"
                                    >Négyévszakos
                                </template>
                                gumi</router-link
                            >
                        </li>
                        <template
                            v-if="
                                params[0] === 'teli' ||
                                    params[0] === 'nyari' ||
                                    params[0] === 'negyevszakos'
                            "
                        >
                            <li v-if="params[2] && params[3] && params[4]">
                                <router-link
                                    :to="
                                        '/' +
                                            params[0] +
                                            '-' +
                                            params[1] +
                                            '-' +
                                            params[2] +
                                            '-' +
                                            params[3] +
                                            '-' +
                                            params[4]
                                    "
                                    >{{ params[2] }} {{ params[3] }} R{{
                                        params[4]
                                    }}
                                    <template v-if="params[0] === 'nyari'"
                                        >Nyári
                                    </template>
                                    <template v-if="params[0] === 'teli'"
                                        >Téli
                                    </template>
                                    <template
                                        v-if="params[0] === 'negyevszakos'"
                                        >Négyévszakos
                                    </template>
                                    gumi</router-link
                                >
                            </li>
                        </template>
                        <template
                            v-if="
                                params[1] === 'teli' ||
                                    params[1] === 'nyari' ||
                                    params[1] === 'negyevszakos'
                            "
                        >
                            <li v-if="params[3] && params[4] && params[5]">
                                <router-link
                                    :to="
                                        '/' +
                                            params[1] +
                                            '-' +
                                            params[2] +
                                            '-' +
                                            params[3] +
                                            '-' +
                                            params[4] +
                                            '-' +
                                            params[5]
                                    "
                                >
                                    <template v-if="params[3] !== 'null'">
                                        {{ params[3] }} {{ params[4] }} R{{
                                            params[5]
                                        }}
                                    </template>
                                    <template v-if="params[1] === 'nyari'"
                                        >Nyári
                                    </template>
                                    <template v-if="params[1] === 'teli'"
                                        >Téli
                                    </template>
                                    <template
                                        v-if="params[1] === 'negyevszakos'"
                                        >Négyévszakos
                                    </template>
                                    gumi</router-link
                                >
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container py-4">
            <div class="row">
                <div class="col-md-6">
                    <router-link
                        :to="{ name: 'Compare', query: { ids: comparList } }"
                        >Kijelölt termékek összehasonlítása</router-link
                    >
                </div>
                <div class="col-md-6">
                    <div class="d-flex align-items-center justify-content-end">
                        <label class="me-4">Rendezés</label>
                        <div class="custom-select w-30">
                            <select
                                class="form-control input-small"
                                v-model="sortBy"
                                v-on:change="setSortBy"
                            >
                                <option value="price_desc"
                                    >Ár szerint csökkenő</option
                                >
                                <option value="price_asc"
                                    >Ár szerint növekvő</option
                                >
                                <option value="star_desc">Legnépszerűbb</option>
                                <option value="tat_desc"
                                    >Készlet szerint</option
                                >
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section>
            <div class="container">
                <template v-for="(item, index) in model.data">
                    <ProductRow
                        v-on:setCompareId="setCompareId"
                        v-bind:key="'product-' + index"
                        :product-data="item"
                    ></ProductRow>
                </template>
            </div>
        </section>
        <div class="container" v-if="model && model.meta">
            <div class="row">
                <div class="col-md-12">
                    <ul class="pagination justify-content-center my-4">
                        <template v-for="(item, index) in model.meta.links">
                            <li
                                class="page-item"
                                v-if="item.label === '&laquo; Previous'"
                                v-bind:class="{ disabled: item.url === null }"
                                v-bind:key="'pagination-prev-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="minusPage"
                                    aria-label="Previous"
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li
                                class="page-item"
                                v-bind:class="{ disabled: item.url === null }"
                                v-else-if="item.label === 'Next &raquo;'"
                                v-bind:key="'pagination-next-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="plusPage"
                                    aria-label="Next"
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                            <li
                                class="page-item"
                                v-bind:class="{ active: item.active }"
                                v-else
                                v-bind:key="'pagination-' + index"
                            >
                                <a
                                    class="page-link"
                                    style="cursor: pointer"
                                    v-on:click="setPage(item.label)"
                                    >{{ item.label }}</a
                                >
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <ul class="text-cloud">
                            <li
                                v-for="(item, index) in model.avail"
                                v-bind:key="'available-' + index"
                            >
                                <router-link
                                    v-if="
                                        params[0] === 'nyari' ||
                                            params[0] === 'teli' ||
                                            params[0] === 'negyevszakos'
                                    "
                                    :to="
                                        '/' +
                                            params[0] +
                                            '-' +
                                            params[1] +
                                            '-' +
                                            item.width +
                                            '-' +
                                            item.profile +
                                            '-' +
                                            item.coll
                                    "
                                    >{{ item.width }}/{{ item.profile }}R{{
                                        item.coll
                                    }}
                                    {{ paramSwitcher(params[0]) }}
                                    {{ params[1] }}</router-link
                                >
                                <router-link
                                    v-if="
                                        params[1] === 'nyari' ||
                                            params[1] === 'teli' ||
                                            params[1] === 'negyevszakos'
                                    "
                                    :to="
                                        '/' +
                                            params[1] +
                                            '-' +
                                            params[2] +
                                            '-' +
                                            item.width +
                                            '-' +
                                            item.profile +
                                            '-' +
                                            item.coll
                                    "
                                    >{{ item.width }}/{{ item.profile }}R{{
                                        item.coll
                                    }}
                                    {{ paramSwitcher(params[1]) }}
                                    {{ params[2] }}</router-link
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ProductRow from "@/components/ProductRow";
// import ApiService from "@/core/services/api.service";
export default {
    name: "ProductList",
    components: { ProductRow },
    data() {
        return {
            model: {},
            page: 1,
            sortBy: "price_desc",
            comparList: []
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        params: function() {
            return this.$route.params.slug.split("-");
        }
    },
    beforeMount() {
        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }
        if (this.$route.query.sortBy) {
            this.sortBy = this.$route.query.sortBy;
        }
        this.getSearchData();
        this.getPageDataSeo();
    },
    watch: {
        $route: {
            handler: function() {
                if (this.$route.query.page) {
                    this.page = this.$route.query.page;
                }
                if (this.$route.query.sortBy) {
                    this.sortBy = this.$route.query.sortBy;
                }
                this.getSearchData();
                this.getPageDataSeo();
            },
            deep: true
        }
    },
    methods: {
        paramSwitcher(pm) {
            switch (pm) {
                case "nyari":
                    return "nyári";
                case "teli":
                    return "téli";
                case "negyevszakos":
                    return "négyévszakos";
                default:
                    return pm;
            }
        },
        setCompareId(data) {
            if (data.checked) {
                this.comparList.push(data.id);
            } else {
                const index = this.comparList.indexOf(data.id);
                if (index > -1) {
                    this.comparList.splice(index, 1); // 2nd parameter means remove one item only
                }
            }
        },
        setSortBy() {
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            arr["sortBy"] = this.sortBy;
            this.$router
                .push({
                    name: "ProductList",
                    params: this.$route.params,
                    query: arr
                })
                .catch(() => {});
        },
        minusPage() {
            this.page = parseInt(this.page) - 1;
            this.setPage(this.page);
        },
        plusPage() {
            this.page = parseInt(this.page) + 1;
            this.setPage(this.page);
        },
        setPage(pg) {
            this.page = pg;
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            arr["page"] = this.page;
            this.$router
                .push({
                    name: "ProductList",
                    params: this.$route.params,
                    query: arr
                })
                .catch(() => {});
        },
        getSearchData() {
            let arr = JSON.parse(JSON.stringify(this.$route.query));
            if (
                this.params[0] === "nyari" ||
                this.params[0] === "teli" ||
                this.params[0] === "negyevszakos"
            ) {
                if (this.params[0] === "nyari") {
                    arr["m1nev"] = "Nyári abroncs";
                } else if (this.params[0] === "teli") {
                    arr["m1nev"] = "Téli abroncs";
                } else {
                    arr["m1nev"] = "Négyévszakos abroncs";
                }

                if (this.params.length > 2) {
                    arr["width"] = this.params[2];
                    arr["profile"] = this.params[3];
                    arr["coll"] = this.params[4];
                }
            } else if (
                isNaN(parseInt(this.params[0])) &&
                (this.params[1] === "nyari" ||
                    this.params[1] === "teli" ||
                    this.params[1] === "negyevszakos")
            ) {
                arr["emarka"] = this.params[0];
                if (this.params[1] === "nyari") {
                    arr["m1nev"] = "Nyári abroncs";
                } else if (this.params[1] === "teli") {
                    arr["m1nev"] = "Téli abroncs";
                } else {
                    arr["m1nev"] = "Négyévszakos abroncs";
                }

                if (this.params.length > 3) {
                    arr["width"] = this.params[3];
                    arr["profile"] = this.params[4];
                    arr["coll"] = this.params[5];
                }
            } else {
                arr["width"] = this.params[0];
                arr["profile"] = this.params[1];
                arr["coll"] = this.params[2];
            }
            arr["page"] = this.page;
            arr["sortBy"] = this.sortBy;

            this.$http
                .get(`${this.url}/products/list?${this.buildQueryString(arr)}`)
                .then(response => {
                    this.model = response.data;
                });
        }
    }
};
</script>

<style scoped></style>
